<template>
    <transition name="fade">
        <div v-if="showModalNumber" id="modal-call">
            <a style="position: absolute; width: 100%; height: 100%; z-index: 10001;"
                @click="() => { this.showModalNumber = false }"></a>
            <div class="modal-dialog">
                <div class="modal-content ">
                    <div class="modal-header-container">

                        <div class="modal-header ">
                            <button type="button" class="btn-close" @click="showModalNumber = false">
                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="30" fill="currentColor"
                                    class="bi bi-x" viewBox="0 0 20 20">
                                    <path
                                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                </svg>
                            </button>
                        </div>
                        <div class="modal-header ">
                            <h5 class="modal-title" id="exampleModalLabel">{{ translation.translate("chooseTariffModal",
                                "title") }} <br>{{ translation.translate("chooseTariffModal", "title-1") }} </h5>
                        </div>
                        <p class="description">{{ translation.translate("chooseTariffModal", "description") }} <br>{{
                            translation.translate("chooseTariffModal", "description1") }}</p>
                        <div class="range-input-container d-flex flex-column  justify-content-center">
                            <div class="d-flex justify-content-center">
                                <div class="document-amount-info-container">
                                    <p id="input-range-value" class="range-value " style="color: #fff !important;">{{
                                        this.inputRangeValue
                                        }}</p>
                                </div>
                            </div>
                            <input id="input-range" type="range" v-model="inputRangeValue" min="0" max="1000" step="1"
                                class="document-range-input">
                            <div class="d-flex justify-content-between">
                                <p>0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                                <p>250&nbsp;&nbsp;</p>
                                <p>500&nbsp;</p>
                                <p>750</p>
                                <p>1000</p>
                            </div>
                        </div>
                        <p class="description">{{ translation.translate("chooseTariffModal", "phone") }}</p>
                        <div class="phone-number-input-container">
                            <input id="phoneNumberInput" class="phone-number-input" type="text" v-model="phoneNumber">
                        </div>
                        <p class="description" style="margin-top: 5px; color: crimson;">{{ this.errorMessage }}</p>
                        <div style="margin-top: 20px; margin-bottom: 20px;">
                            <input type="button" class="article-questions-btn modal_docs_count" @click="sendPhoneNumber"
                                style="padding: 7px 40px; font-size: 18px;"
                                :value="translation.translate('chooseTariffModal', 'btn')">
                            <div class="mt-3 d-flex justify-content-center">
                                <p @click="dontShowModal()"
                                    style="text-decoration: underline !important; cursor: pointer"
                                    class="description text-nowrap">{{ translation.translate("chooseTariffModal",
                                    "dontShow") }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import translate from '../../../translation/translate'
import telegramSerrvice from './services/telegram-service'
export default {
    name: 'modal-call',
    data() {
        return {
            showModalNumber: false,
            inputRangeValue: 100,
            phoneNumber: "+998_________",
            errorMessage: '',
            translation: translate
        }
    },
    methods: {
        sendPhoneNumber() {
            if (!isNaN(this.phoneNumber.substring(1, this.phoneNumber.length))) {
                let text = `Edo.Faktura.uz\n\nКоличество документов: ${this.inputRangeValue}\nНомер телефона: ${this.phoneNumber}`
                telegramSerrvice.sendMessage(text)
                localStorage.setItem('sendPhoneNumber', 'true');
                this.showModalNumber = false;
            }
            else {
                this.errorMessage = 'Введите правильный номер телефона'
            }
        },
        dontShowModal() {
            localStorage.setItem('sendPhoneNumber', 'true');
            this.showModalNumber = false;
        }
    },
    watch: {
        'phoneNumber'(to, from) {
            let toIndex = to.indexOf('_');
            let fromIndex = from.indexOf('_');
            if (to.startsWith('+998') == false) {
                this.phoneNumber = from
                return
            }
            if (!isNaN(to.substring(1, toIndex == -1 ? to.length : toIndex))) {

                if (to.substring(1, toIndex == -1 ? to.length : toIndex) !== from.substring(1, fromIndex == -1 ? from.length : fromIndex) && toIndex != -1 && to.length > from.length) {
                    this.phoneNumber = to.replace('_', '');
                    return
                }
                else {
                    if (to.length < 13) {
                        this.phoneNumber = to + '_'
                        return
                    }

                }
                if (to.length > 13) {
                    this.phoneNumber = from.substring(0, 13)
                    return
                }
            }
            else {
                this.phoneNumber = from
                return
            }
        }
    },
    mounted() {
        window.onclick = function () {
            this.showModalNumber = false
        }
        
        if (localStorage.getItem('sendPhoneNumber') != 'true') {

            setTimeout(() => {
                this.showModalNumber = true;
                
            }, 10000)
            setTimeout(() => {
                let inputValue = document.getElementById('input-range-value');
                let input = document.getElementById('input-range');
                input.addEventListener("input", function (e) {
                    inputValue.style.left = (e.target.value) * 100 / 1000 + '%';
                });
                let phoneInput = document.getElementById('phoneNumberInput');
                phoneInput.addEventListener('input', function () {
                    let position = phoneInput.value.indexOf('_');
                    phoneInput.setSelectionRange(position, position);
                })
                phoneInput.addEventListener('click', function () {
                    let position = phoneInput.value.indexOf('_');
                    phoneInput.setSelectionRange(position, position);
                })
            },10100)
        }
       
    }
}
</script>

<style scoped>
#modal-call {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    background: rgba(0, 0, 0, 0.295);
    left: 0;
    z-index: 10000;
}

/*start range input css */
.range-input-container {
  margin: 25px 0;
  position: relative;
  width: 80%;
  font-size: 12px;
}

.range-input-container .document-amount-info-container {
  width: 95.7%;
}
.range-value {
  margin: 0;
  position: relative;
  color: #fff;
  width: 35px;
  height: 35px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-left: 3px;
  transform: translateX(-55%);
  line-height: 55px;
  z-index: 2;
  left: 10%;
  cursor: grab;
}

.range-value::after {
    position: absolute;
    content: "";
    background-color: #a9a9a9;
    width: 40px;
    height: 40px;
    transform: translateX(-45%) rotate(45deg);
    z-index: -1;
    left: 50%;
    border-top-left-radius: 50%;
    border-top-right-radius: 55%;
    border-bottom-left-radius: 55%;

}


input[type="range"] {
    font-size: 1.5rem;
    width: 100%;

}

input[type="range"] {
    color: #72BD49;
    --thumb-height: 0.9em;
    --track-height: 4px;
    --track-color: rgba(0, 0, 0, 0.2);
    --clip-edges: 0.125em;

}



/* === range commons === */
input[type="range"] {
    position: relative;
    background: #fff0;
    overflow: hidden;
}

input[type="range"]:active {
    cursor: grabbing;
}

input[type="range"]:disabled {
    filter: grayscale(1);
    opacity: 0.3;
    cursor: not-allowed;
}

/* === WebKit specific styles === */
input[type="range"],
input[type="range"]::-webkit-slider-runnable-track,
input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    transition: all ease 100ms;
    height: var(--thumb-height);
}

input[type="range"]::-webkit-slider-runnable-track,
input[type="range"]::-webkit-slider-thumb {
    position: relative;

}

input[type="range"]::-webkit-slider-thumb {
    --thumb-radius: calc((var(--thumb-height) * 0.5) - 1px);
    --clip-top: calc((var(--thumb-height) - var(--track-height)) * 0.5 - 0.5px);
    --clip-bottom: calc(var(--thumb-height) - var(--clip-top));
    --clip-further: calc(100% + 1px);
    --box-fill: calc(-100vmax - var(--thumb-width, var(--thumb-height))) 0 0 100vmax currentColor;

    width: var(--thumb-width, var(--thumb-height));
    background: linear-gradient(currentColor 0 0) scroll no-repeat left center / 50% calc(var(--track-height) + 1px);
    background-color: currentColor;
    box-shadow: var(--box-fill);
    border-radius: var(--thumb-width, var(--thumb-height));

    filter: brightness(100%);
    clip-path: polygon(100% -1px,
            var(--clip-edges) -1px,
            0 var(--clip-top),
            -100vmax var(--clip-top),
            -100vmax var(--clip-bottom),
            0 var(--clip-bottom),
            var(--clip-edges) 100%,
            var(--clip-further) var(--clip-further));

}



input[type="range"]:active::-webkit-slider-thumb {
    filter: brightness(var(--brightness-down));
    cursor: grabbing;
}

input[type="range"]::-webkit-slider-runnable-track {
    background: linear-gradient(var(--track-color) 0 0) scroll no-repeat center / 100% calc(var(--track-height) + 1px);

}

input[type="range"]:disabled::-webkit-slider-thumb {
    cursor: not-allowed;
}

/* end range input css */
.fade-enter-active,
.fade-leave-active {
    transition: opacity .5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active до версии 2.1.8 */
    {
    opacity: 0;
}

.green-span {
    color: #72bd49;
}

.modal-dialog {
    width: 55%;
    z-index: 100000000;
}

.modal-header {
    border-bottom: none;
    justify-content: center;
    padding: 0;
}

.modal-body {
    padding: 30px 0 30px 0;
}

.modal-content {
    position: relative !important;
    margin: 100px 0 0 auto !important;
    background: #fff;
    border-radius: 20px !important;
    z-index: 1000000000;

}

.modal-dialog .modal-content .description {
    font-size: 15px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    color: #747474;
    justify-content: center;
    width: 80%;
    padding: 13px 5px 10px 5px;
    margin: 0;

}

.modal-header-container {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.modal-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 26px;
    padding: 32px 0 0;
    color: #4A4A4A;
    text-align: center;
}

.btn-close {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 999;
    width: 32px;
    height: 30px;
    border-radius: 20px 20px;
    border: none;
    margin: 0 0 0 auto !important;
    background: #F3F3F3;
    transition: 300ms;
    padding-top: 3px;


}

.btn-close:hover {
    background: #cecccc;
}

.btn-close svg {
    color: #747474;
    margin-left: -1px;
}

.modal-dialog .button-call {
    background: #72bd49;
    border-radius: 0px 15px 15px 0px;
    font-weight: 500;
    color: #fff;
    padding: 12px 20px;
    cursor: pointer;
    text-decoration: none;
    margin-left: 10px;
    transition: 300ms;
}

.phone-number-input-container {
    width: 220px;
    height: 40px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 15px 0px #00000033;

}

.phone-number-input-container .phone-number-input {
    width: 100%;
    outline: none;
    border: none;
    background-color: transparent;
    padding: 5px 10px 5px 10px;
    text-align: center;
    letter-spacing: 2px;
    color: #676767;
}

.modal-dialog .button-call:hover {
    box-shadow: 0 0 10px #72bd49;
}

/* .modal-dialog .form-call {
        width: 384px;
        background: white;
        border-radius: 15px 0px 0px 15px;
        border: 1px solid #DBDBDB;
        font-weight: 500;
        color: #5A5A5A;
        padding: 10px 20px;
    } */

/* .modal-dialog .form-call::placeholder {
        color: #DBDBDB !important;
    } */

@media (max-width: 992px) {

    .modal-dialog {
        max-width: 100%;
    }

    .modal-content {
        width: 100%;
    }

    .modal-dialog .form-call {
        width: 250px;
    }
}

@media (max-width: 576px) {
    .modal-body {
        padding: 0 0 20px 0;
    }

    .modal-dialog {
        width: 90%;
        margin: auto;
    }

    .modal-content {
        width: 100% !important;
        margin: 100px 0 0 0 !important
    }


    .modal-dialog .form-call {
        width: 150px;
        padding: 5px 15px !important;
    }

    .modal-dialog .form-call::placeholder {
        font-size: 11px;
    }

    .modal-dialog .button-call {
        width: 80px !important;
        font-size: 14px;
        margin-left: 5px;
        padding: 9px 15px;
    }

    .btn-close {
        opacity: 1;
    }

    /* .btn-close svg {
        width: 30px;
        height: 20px;
    } */
}
</style>